import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ods-nav-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  template: `<a
    [routerLink]="to"
    routerLinkActive="bg-selected-light border-selected"
    class="flex min-h-8 items-center gap-2 rounded-2xl border border-transparent
    px-4 py-2 outline-2 outline-offset-2 outline-focus hover:border-primary
    focus-visible:border-background-200 focus-visible:outline"
    [attr.data-test-id]="'link-to-' + to"
  >
    <ng-content select="[icon]" />
    <p class="text-left text-sm text-text" data-test-id="link-caption">{{ caption }}</p>
  </a>`,
})
export class NavItemComponent {
  @Input({ required: true }) caption!: string;
  @Input() to: string;

  @HostBinding('attr.role') role = 'menuitem';
}
