/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { faker } from '@faker-js/faker';
import { Resource } from '@ngxp/rest';
import { times, zipObject } from 'lodash-es';
import { ListResource } from '../src/lib/resource/resource.util';
import { isNotUndefined } from '../src/lib/tech.util';
import { DummyListLinkRel, createDummy } from './dummy';

// @ts-ignore
export function toResource<R = T & Resource, T = any>(
  model: T,
  linkRelations: string[] = [],
  embeddedResources?: { [key: string]: Resource | Resource[] },
): R {
  const resource: Resource = {
    ...model,
    _links: {
      self: {
        href: faker.internet.url(),
      },
      ...zipObject(
        linkRelations,
        times(linkRelations.length, () => ({
          href: faker.internet.url(),
        })),
      ),
    },
  };

  if (isNotUndefined(embeddedResources)) {
    resource._embedded = embeddedResources;
  }

  return <any>resource;
}

export function createDummyResource(linkRelations: string[] = []): Resource {
  return toResource(createDummy(), linkRelations);
}

export function createDummyListResource(linkRel: string[] = []): ListResource {
  return toResource({}, [...linkRel], {
    [DummyListLinkRel.LIST]: createDummyResources(),
  });
}

export function createDummyResources(linkRelations: string[] = []): Resource[] {
  return times(10, () => toResource(createDummyResource(), [...linkRelations]));
}

export function createEmptyListResource(linkRelations: string[] = []): ListResource {
  return toResource({}, [...linkRelations]);
}

export function createFilledDummyListResource(
  resources: Resource[],
  linkRelations: string[] = [],
): ListResource {
  return toResource({}, [...linkRelations], {
    [DummyListLinkRel.LIST]: resources,
  });
}
