/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export enum ApiRootLinkRel {
  CONFIGURATION = 'configuration',
  CURRENT_USER = 'currentUser',
  DOWNLOAD_TOKEN = 'downloadToken',
  SEARCH_ALLE = 'search_all',
  SEARCH_MEINE = 'search_my',
  SEARCH_UNASSIGNED = 'search_unassigned',
  ALLE_VORGAENGE = 'vorgaenge_all',
  MEINE_VORGAENGE = 'vorgaenge_my',
  UNASSIGNED = 'vorgaenge_unassigned',
  ALLE_VORGAENGE_NEU = 'vorgaenge_neu_all',
  MEINE_VORGAENGE_NEU = 'vorgaenge_neu_my',
  UNASSIGNED_NEU = 'vorgaenge_neu_unassigned',
  ALLE_VORGAENGE_ANGENOMMEN = 'vorgaenge_angenommen_all',
  MEINE_VORGAENGE_ANGENOMMEN = 'vorgaenge_angenommen_my',
  UNASSIGNED_ANGENOMMEN = 'vorgaenge_angenommen_unassigned',
  ALLE_VORGAENGE_IN_BEARBEITUNG = 'vorgaenge_in_bearbeitung_all',
  MEINE_VORGAENGE_IN_BEARBEITUNG = 'vorgaenge_in_bearbeitung_my',
  UNASSIGNED_IN_BEARBEITUNG = 'vorgaenge_in_bearbeitung_unassigned',
  ALLE_VORGAENGE_BESCHIEDEN = 'vorgaenge_beschieden_all',
  MEINE_VORGAENGE_BESCHIEDEN = 'vorgaenge_beschieden_my',
  UNASSIGNED_BESCHIEDEN = 'vorgaenge_beschieden_unassigned',
  ALLE_VORGAENGE_ABGESCHLOSSEN = 'vorgaenge_abgeschlossen_all',
  MEINE_VORGAENGE_ABGESCHLOSSEN = 'vorgaenge_abgeschlossen_my',
  UNASSIGNED_ABGESCHLOSSEN = 'vorgaenge_abgeschlossen_unassigned',
  ALLE_VORGAENGE_VERWORFEN = 'vorgaenge_verworfen_all',
  MEINE_VORGAENGE_VERWORFEN = 'vorgaenge_verworfen_my',
  UNASSIGNED_VERWORFEN = 'vorgaenge_verworfen_unassigned',
  ALL_WIEDERVORLAGEN = 'vorgaenge_wiedervorlagen_all',
  MY_WIEDERVORLAGEN = 'vorgaenge_wiedervorlagen_my',
  UNASSIGNED_WIEDERVORLAGEN = 'vorgaenge_wiedervorlagen_unassigned',
  ALL_UNGELESENE_NACHRICHTEN = 'vorgaenge_ungelesene_nachrichten_all',
  MY_UNGELESENE_NACHRICHTEN = 'vorgaenge_ungelesene_nachrichten_my',
  UNASSIGNED_UNGELESENE_NACHRICHTEN = 'vorgaenge_ungelesene_nachrichten_unassigned',
  ALLE_VORGAENGE_ZU_LOESCHEN = 'vorgaenge_zu_loeschen_all',
  MEINE_VORGAENGE_ZU_LOESCHEN = 'vorgaenge_zu_loeschen_my',
  UNASSIGNED_ZU_LOESCHEN = 'vorgaenge_zu_loeschen_unassigned',
  DOCUMENTATIONS = 'documentations',
  HINTS = 'hints',
  RESOURCE = 'resource',
}
